import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import useModals from '../components/hooks/useModals';
import { modals } from '../components/modal/Modals';

import useTabs from '../components/hooks/useTabs';

import { roomData, newsData } from '../assets/js/Data';

import { Autoplay, Navigation, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

function Main() {
	// tourApi
	// { type: '구례10경', name: '지리산국립공원(지리산 노고단)', contentId: 127590 },
	// { type: '구례10경', name: '화엄사', contentId: 127923 },
	const [loading, setLoading] = useState(true);
	const key = process.env.REACT_APP_tourAPI;
	const [aroundContent, setAroundContent] = useState([]);
	const getTourData = async (key, curContentId) => {
		const json1 = await (
			await fetch(
				`https://apis.data.go.kr/B551011/KorService1/detailCommon1?serviceKey=${key}&MobileOS=ETC&MobileApp=%EB%B4%89%EC%A5%AC%EB%A5%B4%ED%8E%9C%EC%85%98&_type=json&contentId=127590&defaultYN=Y&firstImageYN=Y&areacodeYN=Y&catcodeYN=Y&addrinfoYN=Y&mapinfoYN=Y&overviewYN=Y`,
			)
		).json();
		const json2 = await (
			await fetch(
				`https://apis.data.go.kr/B551011/KorService1/detailCommon1?serviceKey=${key}&MobileOS=ETC&MobileApp=%EB%B4%89%EC%A5%AC%EB%A5%B4%ED%8E%9C%EC%85%98&_type=json&contentId=127923&defaultYN=Y&firstImageYN=Y&areacodeYN=Y&catcodeYN=Y&addrinfoYN=Y&mapinfoYN=Y&overviewYN=Y`,
			)
		).json();
		setLoading(false);
		setAroundContent([json1.response.body.items.item[0], json2.response.body.items.item[0]]);
	};
	useEffect(() => {
		getTourData(key);
	}, [key]);

	// 스와이퍼
	const [swiper, setSwiper] = useState(null);
	const [slideNum, setSlideNum] = useState(0);
	const swiperParams = {
		modules: [Autoplay, Navigation, Scrollbar],
		onSwiper: setSwiper,
		onSlideChange: () => {
			setSlideNum(swiper.activeIndex);
		},
		scrollbar: {
			draggable: true,
		},
		navigation: true,
		grabCursor: true,
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
		speed: 500,
	};

	// 모달
	const { openModal } = useModals();
	const handleClick = (e) => {
		openModal(modals.News, {
			dataId: Number(e.currentTarget.dataset.id),
		});
	};

	// 객실 탭 변경
	const { currentItem, changeItem } = useTabs(0, roomData);

	// 객실 이미지
	const [imgNum, setImgNum] = useState(0);
	const nextImg = () => {
		if (imgNum >= currentItem.images.length - 1) {
			setImgNum(0);
		} else {
			setImgNum((prev) => prev + 1);
		}
	};
	const prevImg = () => {
		if (imgNum <= 0) {
			setImgNum(currentItem.images.length - 1);
		} else {
			setImgNum((prev) => prev - 1);
		}
	};

	// 문자열 html으로 변환
	const stringToHTML = function (str) {
		var dom = document.createElement('div');
		dom.innerHTML = str;
		return dom;
	};

	// 텍스트 복사
	const handleCopyClipBoard = async (text) => {
		try {
			await navigator.clipboard.writeText(text);
			alert('복사 완료되었습니다.');
		} catch (error) {
			alert('복사를 실패하였습니다. 새로고침 후 다시시도 해보시기 바랍니다.');
		}
	};

	// 계절포매팅
	const [season, setSeason] = useState();
	const [mainSlides, setMainSlides] = useState([{ caption: '', subTitle: '' }]);
	useEffect(() => {
		const curMonth = new Date().getMonth() + 1;
		if (curMonth >= 2 && curMonth <= 10) {
			setSeason('ss');
			setMainSlides([
				{
					caption: '푸르른 하늘과 맑은 계곡이 있는 봉쥬르 펜션 전경',
					subTitle: '눈 뜨면 하늘 푸르름 가득한 곳, 귀 열면 맑은 계곡물 소리가 즐거운 곳',
				},
				{
					caption: '시원하게 쏟아지는 폭포',
					subTitle: '한 걸음 나서서 찰랑거리는 돌다리 지나면 소나무 향기 가득한 숲 오솔길',
				},
				{
					caption: '비오는날 객실 데크에서 바라보는 풍경',
					subTitle: '자연의 감사함을 가득 담은 곳, 마음이 힘을 얻어 치유되어지는 시간들',
				},
				{
					caption: '추억이 되고, 힐링이 되는 바베큐시간',
					subTitle: '함께하는 여행길 온전히 사랑하는 사람들과의 소중한 공간으로 추억되시길',
				},
				{
					caption: '고즈넉한 분위기가 있는 봉쥬르 펜션 야경',
					subTitle: '눈 뜨면 하늘 푸르름 가득한 곳, 귀 열면 맑은 계곡물 소리가 즐거운 곳',
				},
				{
					caption: '빨갛게 타오르는 숯불',
					subTitle: '한 걸음 나서서 찰랑거리는 돌다리 지나면 소나무 향기 가득한 숲 오솔길',
				},
				{
					caption: '푸르른 숲속',
					subTitle: '자연의 감사함을 가득 담은 곳, 마음이 힘을 얻어 치유되어지는 시간들',
				},
				{
					caption: '벚꽃 가득한 봉쥬르 펜션 전경',
					subTitle: '함께하는 여행길 온전히 사랑하는 사람들과의 소중한 공간으로 추억되시길',
				},
				{
					caption: '돌담 너머로 피어난 벚꽃나무',
					subTitle: '눈 뜨면 하늘 푸르름 가득한 곳, 귀 열면 맑은 계곡물 소리가 즐거운 곳',
				},
				{
					caption: '시원함을 가득 머금은 돌다리길',
					subTitle: '한 걸음 나서서 찰랑거리는 돌다 지나면 소나무 향기 가득한 숲 오솔길',
				},
				{
					caption: '시원한 대나무 숲 길',
					subTitle: '자연의 감사함을 가득 담은 곳, 마음이 힘을 얻어 치유되어지는 시간들',
				},
				{
					caption: '녹음뒤로 보이는 시원한 폭포',
					subTitle: '함께하는 여행길 온전히 사랑하는 사람들과의 소중한 공간으로 추억되시길',
				},
			]);
		} else {
			setSeason('fw');
			setMainSlides([
				{
					caption: '겨울 맞아 눈이 소복히 쌓인 지리산 전경',
					subTitle: '눈 뜨면 하늘 푸르름 가득한 곳, 귀 열면 맑은 계곡물 소리가 즐거운 곳',
				},
				{
					caption: '하얀 눈이 쌓인 봉쥬르 펜션',
					subTitle: '한 걸음 나서서 찰랑거리는 돌다리 지나면 소나무 향기 가득한 숲 오솔길',
				},
				{
					caption: '피아골 계곡으로 찾아온 겨울',
					subTitle: '자연의 감사함을 가득 담은 곳, 마음이 힘을 얻어 치유되어지는 시간들',
				},
				{
					caption: '지리산 아래 피아골 계곡 너머 봉쥬르 펜션 야경',
					subTitle: '함께하는 여행길 온전히 사랑하는 사람들과의 소중한 공간으로 추억되시길',
				},
				{
					caption: '눈 소복히 쌓인 피아골 계곡가는 돌계단',
					subTitle: '눈 뜨면 하늘 푸르름 가득한 곳, 귀 열면 맑은 계곡물 소리가 즐거운 곳',
				},
				{
					caption: '겨울이 찾아온 봉쥬르 펜션',
					subTitle: '한 걸음 나서서 찰랑거리는 돌다리 지나면 소나무 향기 가득한 숲 오솔길',
				},
				{
					caption: '비오는날 데크에서 바라보는 뷰',
					subTitle: '자연의 감사함을 가득 담은 곳, 마음이 힘을 얻어 치유되어지는 시간들',
				},
				{
					caption: '봉쥬르 펜션따라 둘러진 돌담에도 눈이 쌓인 모습',
					subTitle: '함께하는 여행길 온전히 사랑하는 사람들과의 소중한 공간으로 추억되시길',
				},
			]);
		}
	}, []);

	// 네이버 지도 api
	const mapElement = useRef(null);
	const { naver } = window;
	useEffect(() => {
		const location = new naver.maps.LatLng(35.202015, 127.60101);
		const mapOptions = {
			center: location,
			zoom: 15,
			zoomControl: false,
			draggable: false,
			pinchZoom: false,
			scrollWheel: false,
		};

		const map = new naver.maps.Map(mapElement.current, mapOptions);
		new naver.maps.Marker({
			position: location,
			map,
		});
	});

	return (
		<div className="content main">
			{/* ============================== 메인비주얼 시작 ============================== */}
			<div className="main-slide slide-figure">
				<Swiper {...swiperParams}>
					{mainSlides.map((slide, i) => (
						<SwiperSlide key={i}>
							<figure className="auto-height-figure">
								<img src={`${process.env.PUBLIC_URL}/img/mainslide_${season}/${i}.jpg`} alt="" />
								<figcaption>{slide.caption}</figcaption>
							</figure>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
			<div className="visual-text">
				<div>
					<h2 className="title">
						지리산 피아골 계곡
						<br /> 섬진강 뷰
						<br /> 펜션 봉쥬르
					</h2>
					<p className="h3 sub-title">{mainSlides[slideNum].subTitle}</p>
					<p className="text">
						눈 뜨면 하늘 푸르름 가득한 곳, 귀 열면 맑은 계곡물 소리가 즐거운 봉쥬르 펜션에서 자연이 베풀어 주는 아름다움으로 사랑하는 사람들과 함께하는 여행길 소중한 공간으로 추억되시길 바랍니다.
					</p>
					<p className="text">
						문의사항은 <b>010-8746-1409</b>로 전화주세요. 친절하게 안내해드리겠습니다. 깨끗하고 편안한 휴식일 수 있도록 정성을 더하겠습니다. 감사합니다.
					</p>
				</div>
				<Link className="btn-viewall designed-border" to="about">
					<i className="border-top"></i>
					<div className="border-inner">
						<div className="pb">
							<i className="logo"></i>
							<div>
								<p>Pension Bonjour</p>
								<h1 className="h4">봉쥬르 펜션</h1>
							</div>
						</div>
						<span>펜션정보 자세히보기</span>
					</div>
					<i className="border-bottom"></i>
				</Link>
			</div>
			{/* ============================== 메인비주얼  끝  ============================== */}
			{/* ============================== 객실소개 시작 ============================== */}
			<div className="rooms-tit">
				<div className="title with-viewmore">
					<h2>객실소개</h2>
					<Link to="/rooms" className="h4">
						더보기
					</Link>
				</div>
				<div className="tab-wrap">
					<div className="tab-title">
						{roomData.map((room) => (
							<button
								key={room.id}
								className={currentItem.id === room.id ? 'h3 active' : 'h3'}
								onClick={() => {
									changeItem(room.id);
									setImgNum(0);
								}}
							>
								<span className="en">{room.en}</span>
								<span className="ko">{room.ko}</span>
							</button>
						))}
					</div>
				</div>
			</div>
			<div className="rooms-figure">
				{currentItem.images.map((img, i) => (
					<figure className={`auto-height-figure ${imgNum === i ? 'active' : ''}`} key={i}>
						<img src={`${process.env.PUBLIC_URL}/img/room/${img}.jpg`} alt="" />
						<figcaption>{currentItem.ko} 객실 내부의 모습을 담은 사진</figcaption>
					</figure>
				))}
			</div>
			<div className="rooms-text">
				<div>
					<h3>객실정보</h3>
					<p className="text">{currentItem.info}</p>
				</div>
				<div>
					<h4>인원</h4>
					<p className="text">{currentItem.personnel}</p>
				</div>
				<div>
					<h4>객실구성</h4>
					<p className="text">{currentItem.config}</p>
				</div>
				<div>
					<h4>침대</h4>
					<p className="text">{currentItem.bed}</p>
				</div>
				<div>
					<h4>객실크기</h4>
					<p className="text">{currentItem.size}</p>
				</div>
				<div className="control">
					<button onClick={prevImg}>&lt;</button>
					<span>
						{imgNum + 1} / {currentItem.images.length}
					</span>
					<button onClick={nextImg}>&gt;</button>
				</div>
			</div>
			{/* ============================== 객실소개  끝  ============================== */}
			{/* ============================== 정보(오시는길, 예약안내) 시작 ============================== */}
			<div className="info auto-height">
				<div className="info-waytocome">
					<div className="title with-viewmore">
						<h2>펜션정보</h2>
						<Link to="/about" className="h4">
							더보기
						</Link>
					</div>
					<div className="map" ref={mapElement} style={{ height: '31rem' }} />
					<p className="h4 text">
						전라남도 구례군 토지면 섬진강대로 4310-20 봉쥬르펜션
						<button
							className="btn-copy"
							onClick={() => {
								handleCopyClipBoard('전라남도 구례군 토지면 섬진강대로 4310-20 봉쥬르펜션');
							}}
						></button>
					</p>
					<p className="text">은어마을 펜션단지 입구 첫번째에 위치한 지리산 피아골 계곡 펜션입니다.</p>
				</div>
				<button className="auto-height-component designed-border">
					<i className="border-top"></i>
					<a
						className="border-inner h4 pc-cont"
						href="https://map.naver.com/v5/directions/-/14204480.014504831,4191368.32659363,%EB%B4%89%EC%A5%AC%EB%A5%B4%20%ED%8E%9C%EC%85%98,1708028749,PLACE_POI/-/transit?c=15,0,0,0,dh"
						target="_blank"
						rel="noreferrer"
						title="새창"
					>
						네이버지도 길찾기
					</a>
					<a className="border-inner h4 ta-cont" href="https://m.place.naver.com/accommodation/1708028749/location" target="_blank" rel="noreferrer" title="새창">
						네이버지도 길찾기
					</a>
					<i className="border-bottom"></i>
				</button>
				<div className="info-reservation">
					<div className="title with-viewmore">
						<h2>예약안내</h2>
						<Link to="/reservation" className="h4">
							더보기
						</Link>
					</div>
					<table>
						<colgroup>
							<col width="80px" />
						</colgroup>
						<tbody>
							<tr>
								<th>입실시간</th>
								<td>당일 15:00</td>
							</tr>
							<tr>
								<th>퇴실시간</th>
								<td>익일 11:00</td>
							</tr>
							<tr>
								<th>인원 추가요금</th>
								<td>
									<ul className="list-style">
										<li className="bul">성인, 아동 20,000원</li>
										<li className="bul">유아 10,000원</li>
										<li className="bul">반려동물 5kg미만 10,000원, 5~20kg 20,000원, 20kg 이상 30,000원</li>
									</ul>
								</td>
							</tr>
							<tr>
								<th>바베큐숯 이용요금</th>
								<td>
									<ul className="list-style">
										<li className="bul">
											기본 20,000원
											<ul>
												<li className="star">숯, 철망 추가시 5,000원</li>
											</ul>
										</li>
									</ul>
								</td>
							</tr>
							<tr>
								<th>전기그릴 이용요금</th>
								<td>20,000원</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			{/* ============================== 정보(오시는길, 예약안내)  끝  ============================== */}
			{/* ============================== 주변소개 시작 ============================== */}
			<div className="around">
				<div className="title with-viewmore">
					<h2>주변소개</h2>
					<Link to="/around" className="h4">
						더보기
					</Link>
				</div>
				<ul className="around-list">
					{loading
						? '주변소개 정보를 불러오는중입니다. 잠시만 기다려주세요'
						: aroundContent.map((around, i) => (
								<li key={around.contentid}>
									<figure>
										<img src={around.firstimage} alt="" />
										<figcaption title="around.title">{around.title} 사진</figcaption>
									</figure>
									<div className="around-text">
										<h4>{around.title}</h4>
										<p className="text">{around.overview}</p>
										<p className="source">
											<span className="pc">데이터 </span>제공 : 한국관광공사
										</p>
									</div>
								</li>
						  ))}
				</ul>
			</div>
			{/* ============================== 주변소개  끝  ============================== */}
			{/* ============================== 자주하는질문 시작 ============================== */}
			<div className="faq">
				<div className="title with-viewmore">
					<h2>FAQ</h2>
					<Link to="/faq" className="h4">
						더보기
					</Link>
				</div>
				<div className="auto-height">
					<div className="auto-height-content">
						<h4 className="sub-title">궁금하신 사항은 우측 상단의 더보기를 클릭하여 확인해주세요. 자주하는질문이 정리되어있습니다.</h4>
						<p className="text">
							그 외 추가적인 문의사항이 있을 시 <b>010-8746-1409</b>로 전화주시면 친절하게 안내해드리겠습니다. 감사합니다.
						</p>
					</div>
					<button className="auto-height-component designed-border">
						<i className="border-top"></i>
						<a href="tel:010-8746-1409" className="border-inner h4">
							바로 전화하기
						</a>
						<i className="border-bottom"></i>
					</button>
				</div>
			</div>
			{/* ============================== 자주하는질문  끝  ============================== */}
			{/* ============================== 뉴스 시작 ============================== */}
			<div className="news">
				{[newsData[0], newsData[1], newsData[2]].map((news) => {
					return (
						<button onClick={handleClick} key={news.id} data-id={news.id}>
							<div className="thumb">
								<img src={`${process.env.PUBLIC_URL}/img/news/${news.id}0.jpg`} alt="" />
							</div>
							<div className="cont">
								<h4 className="tit">{news.title}</h4>
								<p className="date">{news.date}</p>
								<p
									className="text"
									dangerouslySetInnerHTML={{
										__html: stringToHTML(news.html).innerText,
									}}
								></p>
							</div>
						</button>
					);
				})}
				{/* ============================== 뉴스  끝  ============================== */}
			</div>
		</div>
	);
}

export default Main;
